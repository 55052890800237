<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockTeaserCategory = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "content";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockTeaserCategory;
  blockPosition: number;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const slot: any = getSlotContent("teaserCategory");

const { getConfigValue } = useCmsElementConfig(slot);
const textStyle: any = getConfigValue("textStyle");
const textColor: any = getConfigValue("textColor");
const teasers: any = getConfigValue("objItems");
</script>

<template>
  <div>
    <div v-if="teasers.length === 1">
      <CmsElementTeaserCategoryWide
        v-for="teaser in teasers"
        :key="teaser.sort_id"
        :position="teaser.position"
        :text-position="teaser.textPosition"
        :title="teaser.title"
        :subtitle="teaser.subtitle"
        :text="teaser.text"
        :textStyle="textStyle"
        :textColor="textColor"
        :buttons="teaser.buttons"
        :gradient="teaser.gradient"
        :gradient-position="teaser.gradientPosition"
        :gradient-intensity="teaser.gradientIntensity"
      >
        <SharedCmsImage
          :src="teaser?.media?.url"
          :crop="teaser?.media?.crop"
          :focus-point="teaser?.media?.focusPoint"
          :alt="teaser.title"
          :width="(teaser.position === 'background') ? 1920 : 960"
          :height="(teaser.position === 'background') ? 1080 : 960"
          :class-img="{
            'h-full max-h-[inherit] w-full object-cover': true,
            'w-full!': teaser.position === 'background',
          }"
          :loading="blockPosition === 0 ? 'eager' : 'lazy'"
        />
      </CmsElementTeaserCategoryWide>
    </div>
    <div v-if="teasers.length > 1" class="s-gutter">
      <div
        class="c-width-narrow grid gap-8"
        :class="{
          'grid-cols-1 md:grid-cols-2': teasers.length === 2,
          'grid-cols-1 md:grid-cols-2 lg:grid-cols-3': teasers.length === 3,
        }"
      >
        <CmsElementTeaserCategorySmall
          v-for="teaser in teasers"
          :key="teaser.sort_id"
          :title="teaser.title"
          :subtitle="teaser.subtitle"
          :text="teaser.text"
          :textStyle="textStyle"
          :buttons="teaser.buttons"
        >
          <SharedCmsImage
            v-if="teaser?.media?.id"
            :src="teaser?.media?.url"
            :crop="teaser?.media?.crop"
            :focus-point="teaser?.media?.focusPoint"
            :alt="teaser.title"
            width="775"
            height="775"
            :loading="blockPosition === 0 ? 'eager' : 'lazy'"
          />
        </CmsElementTeaserCategorySmall>
      </div>
    </div>
  </div>
</template>
